import React from "react";
import styled from "styled-components";


export default ({ children })  => (
    <StyledDiv>
        { children }
    </StyledDiv>
  )

const StyledDiv = styled.div`

    .l-page {
     grid-column: page
    }
    
    
    @media (max-width: 768px) {
        
        .l-page {
        grid-column: text;
        display: flex;
        flex-direction: column;
    }
    }
  
`