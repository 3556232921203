import React from "react";
import styled from "styled-components";
import loadable from '@loadable/component';
import scrollTo from 'gatsby-plugin-smoothscroll'

const listItem = (item) => (
                <li key={item.url + String(Math.floor(Math.random() * 100))}>
                    <Anchor onClick={ () => scrollTo(item.url)}>
                        {item.title}
                    </Anchor>
                </li>
)

const createNestedToc = (item) => {

    console.log(item)
    if (item.items !== undefined) {
        
        return(<ul>
                    {item.title !== undefined ? listItem(item) : undefined}
                <li key={String(Math.floor(Math.random() * 100))}>
                    {item.items.map((child) => (
                        createNestedToc(child)
        ))}
                </li>
            
        </ul>)
    }

    return(<ul>
        {listItem(item)}
    </ul>)
}

export default (headings) => () => {

    console.log("Inside toc!")
    return(
        <d-contents>
        <nav  class="toc figcaption">
            <h3>Contents</h3>
            {headings.map(createNestedToc)}
            </nav>
        </d-contents>
    )
            
}

const Anchor = styled.a`
    cursor: pointer;
`