import React from "react";
import Spinner from "react-spinkit";
import Loadable from 'react-loadable';
import styled from "styled-components";

const Plotly = Loadable({
    loader: () => import(`react-plotly.js`),
    loading: ({ timedOut }) =>
      timedOut ? (
        <blockquote>Error: Loading Plotly timed out.</blockquote>
      ) : (
        <SpinnerContainer>
          <Spinner name="pulse"/>
        </SpinnerContainer> 
      ),
    timeout: 10000,
  })

export default  ({ id, layout, style, config, caption, ...rest }) => {
    
    console.log(rest)
    return (
    <Container id={id}>
      <PlotlyContainer>
      <Plotly
        layout={{
          margin: { t: 0, r: 0, b: 0, l: 0 },
          paper_bgcolor: `rgba(0, 0, 0, 0)`,
          plot_bgcolor: `rgba(0, 0, 0, 0)`,
          font: {
            color: `rgba(0, 0, 0, 0.8)`,
            size: 12,
          },
          // The next 3 options make the plot responsive.
          autosize: true,
          ...layout,
        }}
        style={{ width: `100%`, ...style }}
        useResizeHandler
        config={{
          displayModeBar: false,
          showTips: false,
          ...config,
        }}
        {...rest}
      />
      </PlotlyContainer>
      <figcaption>{caption}</figcaption>
      </Container>
    )
  }



const Container = styled.div`
    width: 100%;
    display: block;
    overflow-x: hidden;
    flex-direction: column;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
`

const PlotlyContainer = styled.div`
  
`

const SpinnerContainer = styled.div`
    margin-bottom: 10vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`