import React from "react";
import styled from "styled-components";
import loadable from '@loadable/component';
import Cite from "citation-js"

export const parseBibliography = (bibtex, template = "apa") => {


    var allCitations = Cite(bibtex).data
    var formattedCitations = [];

    allCitations.forEach( (value) => {
        let bib =  Cite(value).format('bibliography', {
            format : "text",
            template : template
        });

        let citation = Cite(value).format("citation", {
            format : "text",
            template : template
        }).replace("(", "").replace(")", "");


        formattedCitations.push({
            id: value.id,
            bib : bib,
            citation : citation
        })
    });

    return(formattedCitations)
};



export default (bibliography) => () => {

    return(
    
    
    
    <d-citation-list>
    <div id="references" class="references">
    <h3>References</h3>
    <ol>
                {bibliography.map( (item) => (
                    <li id={`ref-${item.id}`} class="citation">
                        {item.bib}
                    </li>
                ))}
    </ol>
    </div>
    </d-citation-list>
    )
            
}

