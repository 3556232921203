import React from "react";
import styled from "styled-components";
import scrollTo from 'gatsby-plugin-smoothscroll';

export default (bibliography) => ({ who}) => {

    const bibId = "#ref-" + who

    console.log(bibliography)
    console.log(who)
    console.log(bibId)
    var citation = bibliography.filter((item) => item.id == who)
                                 .map((item) => item.citation);
    if (citation.length < 1) {
        citation = "???"
    }
    return(<span>
        <a class="citation" onClick={ () => scrollTo(bibId)}>{citation}</a>
    </span>)
}
