import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react";
import Layout from "./layout"
import CodeBlock from "./CodeBlock"
import React from 'react'
import "./styles/article.css"
import Wrapper from "./blog/Wrapper"
import { WrapInlineMath, WrapBlockMath, Ref } from './blog/Math';

import loadable from '@loadable/component';
import LazyPlot from "./LazyPlot";
import Bibliography, {parseBibliography} from './blog/Bibliography';
import Toc from './blog/Toc';
import DistillArticle from './blog/Article';
import Citation from "./blog/Cite";
import FullPageDiv from "./blog/FullPageDiv";
import SEO from "./seo.js";



const Plot = loadable(() => import('react-plotly.js'));



  
export const query = graphql
    `
    query PostsByID($id: String!, $parent_dir : String! ) {
        mdx(
            id: { eq: $id }
        ){
            headings {
                depth
                value
              }
            tableOfContents(maxDepth: 4)
            body
            frontmatter {
                author
                title
                date(formatString: "MMMM Do, YYYY")
                description
                email
            }
        }
        file(
            dir: {eq: $parent_dir}
            extension: {eq: "bib"}
          ) {
            fields {
                content
            }
            birthTime
            absolutePath
            base
            relativePath
            sourceInstanceName
          }
    }
`



export default ({ data }) => {
    const { frontmatter, body } = data.mdx
    const bibtex = data.file.fields.content
    const bibliography = parseBibliography(bibtex);
    const Article = DistillArticle(data.mdx.tableOfContents.items);
    const components = {
        pre: CodeBlock,
        //wrapper : Wrapper,
        InlineMath : WrapInlineMath,
        BlockMath : WrapBlockMath,
        Ref : Ref,
        Plot : LazyPlot,
        Cite : Citation(bibliography),
        Bibliography : Bibliography(bibliography),
        FullPageDiv : FullPageDiv,
        Article : Article,
      }

    
    
    return (
        <Layout theme="white" doAnimate={false}>
        <SEO title={frontmatter.title} description={frontmatter.description}/>
        <div class="distill-html">
            
            <Wrapper>
            <d-title data-sal="fade" data-sal-delay="200" data-sal-easing="easeInQuart">
                <h1>{frontmatter.title}</h1>
                <p> {frontmatter.description}</p>
            </d-title>
            <d-byline data-sal="fade" data-sal-delay="500" data-sal-easing="easeInQuart">
            <div class="byline grid" >
                <div class="authors-affiliations grid">
                    <h3>Author</h3>
                    <h3>Email</h3>
                    <p class="author"> {frontmatter.author} </p>
                    <p>{frontmatter.email}</p>
                </div>
                <div>
                    <h3>Published</h3>
                    <p>{frontmatter.date}</p>
                </div>


            </div>
            </d-byline>
            
            
            <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
            </Wrapper>
            
        </div>
        </Layout>
    )
}

//