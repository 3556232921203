import React from "react";
import { InlineMath, BlockMath } from 'react-katex';
import styled from "styled-components";
import scrollTo from 'gatsby-plugin-smoothscroll';
import katex from 'katex';

export const WrapInlineMath = ({ math, children, id }) => {

    return(<Container id={id}>
    <InlineMath math={math}>{children}</InlineMath>
    </Container>)
}

export const WrapBlockMath = ({ math, children, number}) => {

    let text;
    if (number) {
        text = "(" + number + ")"
    }
    return(
    <Container id={"eq" + number}>
    <EquationDiv>
    <BlockMath math={math}>{children}</BlockMath>
    </EquationDiv>
    <NumberDiv><p style={{"marginBottom" : 0}}>{text}</p></NumberDiv>
    </Container>)
}


export const Ref = ({ id, label }) => {
    return( <a onClick={ () => scrollTo(id)}>{label}</a>)

}


const Container = styled.div`
    display: flex;
    width: 110%;
    

    @media screen and (max-width: 768px) {
     width: 100%;
    }
    
`

const EquationDiv = styled.div`
    float: left;
    width: 110%;
    

    @media screen and (max-width: 768px) {
     width: 90%;
     display: inline-block;
     overflow-x: scroll !important;
    }
`
const NumberDiv = styled.div`
    float: right;
    padding-bottom: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
`