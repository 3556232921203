import React from "react";
import styled from "styled-components";
import TableOfContents from '../Toc'


export default (tocItems) => ({children}) => {

  const Toc = TableOfContents(tocItems)

  return(<div className="article">
          <d-article>
            
            <Toc />
            {children}
            </d-article>
          </div>
  )
}